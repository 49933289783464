import {ChangeDetectorRef, Component, Inject, Input, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators
} from "@angular/forms";
import {ArticlesProperty} from "../../models/articles-property.enum";
import {CategoriesProperty} from "../../models/categories-property.enum";
import {Articles} from "../../models/articles.model";
import {ActivatedRoute, Router} from "@angular/router";
import {DomSanitizer} from "@angular/platform-browser";
import {CategoriesService} from "../../services/categories.services";
import {Categories} from "../../models/categories.model";
import {Observable} from "rxjs";
import {ArticlesService} from "../../services/articles.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {
  CdkDrag,
  CdkDragDrop,
  CdkDragHandle,
  CdkDropList,
  CdkDropListGroup,
  moveItemInArray
} from "@angular/cdk/drag-drop";
import {ImagesService} from "../../services/images.service";
import {ArticlePreviewDialogComponent} from "./article-preview-dialog/article-preview-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {SurveysService} from "../../services/survey.service";
import {Surveys} from "../../models/surveys.model";
import {SurveysProperty} from "../../models/surveys-property.enum";
import {Chair} from "../../models/chair.model";
import {UserService} from "../../services/user.service";
import {ChairProperty} from "../../models/chair-property.enum";
import {TagsService} from "../../services/tags.services";
import {Tag} from "../../models/tag.model";
import {TagProperty} from "../../models/tag-property.enum";
import {Route} from "../../constants/route.constants";
import {ChairService} from "../../services/chair.service";
import {TagImagesDisplayDialogComponent} from "./tag-images-display-dialog/tag-images-display-dialog.component";
import {isPlatformBrowser, NgForOf, NgIf} from "@angular/common";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {SurveyComponent} from "../survey/survey.component";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ArticleUploadLoaderComponent} from "../../loaders/article-upload-loader/article-upload-loader.component";
import {ScheduleDialogComponent} from "./schedule-dialog/schedule-dialog.component";


@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgForOf,
    CdkDropList,
    CdkDrag,
    MatButtonModule,
    MatFormFieldModule,
    MatSelectModule,
    SurveyComponent,
    MatCheckboxModule,
    FormsModule,
    ArticleUploadLoaderComponent,
    CdkDragHandle
  ],
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList> | undefined;
  @ViewChild(CdkDropList) placeholder: CdkDropList | undefined;

  /*Form for the article*/
  public form!: FormGroup;

  /*User if it exsists*/
  chair: Chair | undefined;

  /*Article object for the article from form*/
  @Input()
  article!: Articles;
  /*Articles for preview*/
  public highlight: Articles[] = [];

  /*MEDIA*/

  /*Data for form inputs*/
  public categories: Categories[] = [];
  public surveys: Surveys[] = [];
  public allTags: Tag[] = [];
  public selectedTags: Tag[] = [];
  public receivedTagsControl: Tag[] = [];
  public displayNameOptions: string[] = [];

  /*Media input*/
  imageLinks: any[] = [];
  currentIndex = 0;
  public target: CdkDropList | null;
  public source: CdkDropList | null;

  // Initialize a FormControl
  newLinkControl: FormControl = new FormControl('', Validators.required);

  isUploading: boolean = false
  isSuccess: boolean = false

  selectedFiles?: FileList;

  /*If the user is editing*/
  private isEditing: boolean = false;

  /*Tags Search*/
  userInput = '';

  public articlesProperty = ArticlesProperty;
  public categoriesProperty = CategoriesProperty;
  public surveysProperty = SurveysProperty;

  constructor(
    @Inject(PLATFORM_ID) private platformID: Object,
    private formBuilder: FormBuilder,
    private articlesService: ArticlesService,
    private categoriesService: CategoriesService,
    private userService: UserService,
    private chairService: ChairService,
    private surveysService: SurveysService,
    private imagesService: ImagesService,
    private tagsService: TagsService,
    private activatedRoute: ActivatedRoute,
    public sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private router: Router,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
  ) {
    this.target = null;
    this.source = null;
  }

  /*SAFE HTML CODE AND URLS*/
  embedSafe: any[] = [];

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformID)) {
      this.chairService.getChair().subscribe(chair => {
        this.chair = chair
      });

      /*Check if the user is editing the exsisting article?*/
      const urlSegments = this.activatedRoute.snapshot.url;
      if (urlSegments.length >= 2) {
        // Access the second-to-last segment
        this.isEditing = urlSegments[urlSegments.length - 2].path === Route.EDIT;
      } else {
        // Handle the case where there are not enough segments
        this.isEditing = false; // or set it to some default value
      }

      if (this.isEditing) {
        /*Get the data of the article*/
        // @ts-ignore
        this.imageLinks = this.article[ArticlesProperty.image_list]?.map(item => item);
        this.tagsService.getActiveTags().subscribe(tags => {
          this.allTags = tags;
          this.receivedTagsControl = tags
          if (this.article) {
            // Extract tag names from the article
            const articleTagNames = this.article?.tags?.map(tag => tag.tag);
            // Filter tags that are present in the article
            const selectedTags = this.allTags.filter(tag => articleTagNames?.includes(tag.tag));

            // Remove selectedTags from allTags
            this.allTags = this.allTags.filter(tag => !articleTagNames?.includes(tag.tag));

            // Assign selectedTags to a separate property, if needed
            this.selectedTags = selectedTags;
          }
        });
        this.buildForm()
        this.sanitizeEmbed()
      } else {
        this.tagsService.getActiveTags().subscribe(tags => {
          this.allTags = tags;
          this.receivedTagsControl = tags
        });
        this.buildForm()
      }

      /*Retrieve the user data*/
      //this.chair = this.userService.getChair()

      // Two fixed options and two dynamic options
      if (this.chair) {
        if (this.chair[ChairProperty.accessAllSignatures]) {
          this.chairService.getSignatures().subscribe(
            (signatures: any) => {
              this.displayNameOptions = [
                'Hayat',
              ];
              signatures.forEach((signature: { name: string; }) => {
                this.displayNameOptions.push('Piše: ' + signature.name, 'Prenosi: ' + signature.name);
                // if (signature.initials !== ''){
                // }
                // else {
                //   this.displayNameOptions.push(signature.name);
                // }
              });

              const indexName1 = this.displayNameOptions.indexOf('Prenosi: ' + this.chair?.[ChairProperty.name]!);
              if (indexName1 > -1) {
                // Remove the chairName from its current position
                this.displayNameOptions.splice(indexName1, 1);
                // Add the chairName to the top
                this.displayNameOptions.unshift('Prenosi: ' + this.chair?.[ChairProperty.name]!);

                const indexName2 = this.displayNameOptions.indexOf('Piše: ' + this.chair?.[ChairProperty.name]!);
                if (indexName2 > -1) {
                  // Remove the chairName from its current position
                  this.displayNameOptions.splice(indexName2, 1);
                  // Add the chairName to the top
                  this.displayNameOptions.unshift('Piše: ' + this.chair?.[ChairProperty.name]!);
                }

              }
            },
            (error) => {
              console.error('Error fetching signatures:', error);
            }
          );
        } else {
          this.displayNameOptions = [
            'Piše: ' + this.chair[ChairProperty.name],
            'Prenosi: ' + this.chair[ChairProperty.name],
            'Hayat',
          ];
        }

      }
      /*Retrieve the categories data*/
      this.categoriesService.getCategories().subscribe(
        (response) => {
          this.categories = response;
        },
        (error) => {
          console.error('Failed to get channels:', error);
        }
      )
    }
  }

  get selectedPriority(): number | null {
    return this.form.get(ArticlesProperty.priority)?.value;
  }

  /*BUILDING FORM*/
  buildForm() {
    this.form = this.formBuilder.group({
      [ArticlesProperty.id]: [this.article?.[ArticlesProperty.id] || ''],
      [ArticlesProperty.title]: [this.article?.[ArticlesProperty.title] || '', Validators.required],
      [ArticlesProperty.subtitle]: [this.article?.[ArticlesProperty.subtitle] || '', Validators.required],
      [ArticlesProperty.text]: [this.article?.[ArticlesProperty.text] || ''],
      textEditors: this.formBuilder.array([]),
      displayName: [this.article?.[ArticlesProperty.display_name] || '', Validators.required],
      [ArticlesProperty.category]: [this.article?.[ArticlesProperty.category] || '', Validators.required],
      [ArticlesProperty.image_list]: [this.article?.[ArticlesProperty.image_list] || ''],
      [ArticlesProperty.priority]: [this.article?.[ArticlesProperty.priority] || null],
      [ArticlesProperty.editor]: [this.chair],
      [ArticlesProperty.tags]: [{value: this.article?.[ArticlesProperty.tags] || ''}],
      notification: false,
    });

    if (this.isEditing && this.article) {
      const editorTypes = [
        'text', 'regular', 'bold', 'italic', 'link', 'instagram',
        'youtube', 'facebook', 'tiktok', 'x', 'sofascore', 'survey', 'image'
      ];

      this.article.text.forEach((editor) => {
        for (const type of editorTypes) {
          if (editor[type]) {
            const formGroup = this.formBuilder.group({
              [type]: [editor[type] || '', Validators.required]
            });

            if (type === 'link') {
              formGroup.addControl('linkText', new FormControl(editor.linkText || '', Validators.required));
            } else if (type === 'survey') {
              formGroup.addControl('embed', new FormControl(editor.embed?._id));

              // Fetch surveys only once if not already fetched
              if (!this.surveys?.length) {
                this.surveysService.getSurveys().subscribe(
                  (response) => {
                    this.surveys = response;
                  },
                  (error) => {
                    console.error('Failed to get surveys:', error);
                  }
                );
              }
            }

            this.getText.push(formGroup);
            break; // Stop after finding the first match
          }
        }
      });
    }
  }

  /*MEDIA INPUT FUNCTIONS*/
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.imageLinks, event.previousIndex, event.currentIndex);
  }

  dropContent(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.getText.controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.getText.value, event.previousIndex, event.currentIndex);
  }

  next() {
    this.currentIndex = (this.currentIndex + 1) % this.imageLinks.length;
  }

  prev() {
    this.currentIndex = (this.currentIndex - 1 + this.imageLinks.length) % this.imageLinks.length;
  }

  insertLink(link: string) {
    // Check if the newLink ends with a valid image or video extension
    if (link.match(/\.(mp4|webp|jpg|png|mov|jpeg)$/i)) {
      const imageInfo = {
        url: link,
        tag: '',
      };
      this.imageLinks.push(imageInfo);
      this.newLinkControl.setValue('');
    } else {
      // Display an error or provide feedback to the user
      console.log('Invalid link. Please provide a valid image or video link.');
    }
  }

  selectFiles(event: Event): void {
    // @ts-ignore
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {

        if (this.selectedFiles[i].size > 5 * 1024 * 1024) {
          console.error(`File ${this.selectedFiles[i].name} exceeds the maximum allowed size.`);
          this.snackBar.open(`File ${this.selectedFiles[i].name} exceeds the maximum allowed size.`, 'Close', {
            duration: 3000,
            horizontalPosition: 'right',
            verticalPosition: 'top',
            panelClass: ['error-snackbar']
          });
        } else {
          this.upload(i, this.selectedFiles[i]);
        }
      }
    }
  }

  uploadFiles(): void {
    if (this.selectedFiles) {
      for (let i = 0; i < this.selectedFiles.length; i++) {
        if (this.selectedFiles[i].size)
          this.upload(i, this.selectedFiles[i]);
      }
    }
  }

  upload(idx: number, file: File): void {
    if (file) {

      this.imagesService.upload(file, 'media').subscribe(
        (response) => {
          const imageInfo = {
            url: response.imageUrl,
            tag: '',
          };
          this.imageLinks.push(imageInfo);
        },
        (error) => {
          // Handle error, e.g., display an error message
          console.error('Error creating article:', error);
        });
    }
  }

  public removeImage(link: string): void {
    this.imageLinks.forEach((item, index) => {
      if (link === item.url) this.imageLinks.splice(index, 1);
    });
    if (this.currentIndex === this.imageLinks.length && this.currentIndex != 0) {
      this.currentIndex = this.currentIndex - 1;
    }
  }

  /*MEDIA INPUT FUNCTIONS*/

  /*TAGS INPUT FUNCTIONS*/
  filterTags(): Tag[] {
    const filteredTags = this.allTags
      .filter(tag => tag[TagProperty.tag].toLowerCase().includes(this.userInput.toLowerCase()))
      .slice(0, 20);
    return filteredTags;
  }

  addTag(tag: Tag): void {
    this.selectedTags.push(tag);
    this.allTags = this.allTags.filter(t => t !== tag);
  }

  removeTag(selectedTag: Tag): void {
    this.selectedTags = this.selectedTags.filter(tag => tag !== selectedTag);
    this.allTags.push(selectedTag);
  }

  onEnter(): void {
    const trimmedInput = this.userInput.trim().toUpperCase();
    if (!trimmedInput) {
      return; // Do nothing if the input is empty or contains only whitespace
    }
    const existingTag = this.receivedTagsControl.find(tag => tag.tag === trimmedInput);

    if (this.selectedTags.find(tag => tag.tag === trimmedInput)) {
      this.snackBar.open("Tag je već odabran", 'Close', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        panelClass: ['error-snackbar']
      });
    } else if (existingTag) {
      this.addTag(existingTag);
    } else {
      // If the tag doesn't exist, add it to the database and then add it to the selected tags
      const newTag: Tag = {tag: trimmedInput, active: true, imagesURL: []};
      this.tagsService.create(newTag).subscribe(
        addedTag => {
          this.allTags.push(addedTag);
          this.addTag(addedTag);
        },
        error => {
          console.error('Failed to add new tag:', error);
          // Handle the error (e.g., show a message to the user)
        }
      );
    }
    this.userInput = ''

  }

  /*TAGS INPUT FUNCTIONS*/


  onSubmit() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const articleData: Articles = {
        ...this.form.value,
        [ArticlesProperty.image_list]: this.imageLinks.map((link, index) => ({
          url: link.url,
          order: index,
          tag: link.tag
        })),
        [ArticlesProperty.editor]: this.chair,
        [ArticlesProperty.tags]: this.selectedTags
      };

      const isPriorityNotNull = this.form.get([ArticlesProperty.priority])?.value !== null;
      if (isPriorityNotNull) {
        this.articlesService.getArticleHighlight().subscribe(
          (response) => {
            this.highlight = response;

            const dialogRef = this.dialog.open(ArticlePreviewDialogComponent, {
              maxWidth: '90vw',
              data: {articles: this.highlight, article: articleData},
            });

            dialogRef.afterClosed().subscribe(result => {
              if (result === true) {
                this.handleArticleSubmission(articleData);
              }
            });
          },
          (error) => {
            console.error('Failed to get channels:', error);
          }
        );
      } else {
        this.handleArticleSubmission(articleData);
      }
    } else {
      const titleControl = this.form.get('title');
      const subtitleControl = this.form.get('subtitle');
      const categoryControl = this.form.get('category');
      const signatureControl = this.form.get('displayName');


      if (titleControl?.valid == false) {
        this.snackBar.open("Nedostaje vam naslov", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      } else if (subtitleControl?.value.length < 60) {
        this.snackBar.open("Podnaslov je previse kratak", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      } else if (categoryControl?.valid == false) {
        this.snackBar.open("Niste izabrali kategoriju", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      } else if (signatureControl?.valid == false) {
        this.snackBar.open("Potpis (signature) nije izabran", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      }

      /*Control of validation*/
      /*Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        console.log(`${field}: ${control?.valid}`);
      });*/
    }
  }

  onSchedule() {
    this.form.markAllAsTouched();

    if (this.form.valid) {

      const dialogReff = this.dialog.open(ScheduleDialogComponent, {
        width: '300px', // Adjust the width as per your design
      });

      dialogReff.afterClosed().subscribe((result: any) => {
        if (result) {
          const articleData: Articles = {
            ...this.form.value,
            [ArticlesProperty.image_list]: this.imageLinks.map((link, index) => ({
              url: link.url,
              order: index,
              tag: link.tag
            })),
            [ArticlesProperty.editor]: this.chair,
            [ArticlesProperty.tags]: this.selectedTags,
            schedule_time: result // Assign the entered date and time to schedule_time
          };

          const isPriorityNotNull = this.form.get([ArticlesProperty.priority])?.value !== null;

          if (isPriorityNotNull) {
            this.articlesService.getArticleHighlight().subscribe(
              (response) => {
                this.highlight = response;

                const dialogRef = this.dialog.open(ArticlePreviewDialogComponent, {
                  maxWidth: '90vw',
                  data: {articles: this.highlight, article: articleData},
                });

                dialogRef.afterClosed().subscribe(result => {
                  if (result === true) {
                    this.handleArticleSchedule(articleData);
                  }
                });
              },
              (error) => {
                console.error('Failed to get channels:', error);
              }
            );
          } else {
            this.handleArticleSchedule(articleData);
          }
        }
      })

    } else {
      const titleControl = this.form.get('title');
      const subtitleControl = this.form.get('subtitle');
      const categoryControl = this.form.get('category');


      if (titleControl?.valid == false) {
        this.snackBar.open("Nedostaje vam naslov", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      } else if (subtitleControl?.value.length < 60) {
        this.snackBar.open("Podnaslov je previse kratak", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      } else if (categoryControl?.valid == false) {
        this.snackBar.open("Niste izabrali kategoriju", 'Close', {
          duration: 3000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          panelClass: ['error-snackbar']
        });
      }
    }
  }

  private handleArticleSubmission(articleData: Articles): void {
    let submissionObservable: Observable<Articles>
    if (this.chair && !this.chair[ChairProperty.permissionIndependently]) {
      submissionObservable = this.articlesService.request(articleData)
    } else if (this.isEditing) {
      submissionObservable = this.articlesService.update(articleData[ArticlesProperty.id]!, articleData)
    } else {
      submissionObservable = this.articlesService.create(articleData);
    }

    this.isUploading = true;

    submissionObservable.subscribe(
      () => {
        this.isUploading = false;
        this.isSuccess = true;
        setTimeout(() => {
          this.isSuccess = false;
        }, 2000);

        if (this.isEditing) {
          this.router.navigate([Route.ARTICLE + Route.SEPARATOR + this.article[ArticlesProperty.url_title] + Route.SEPARATOR + this.article[ArticlesProperty.id]]);
        }
      },
      (error) => {
        console.error('Failed to create/update/request article:', error);
      }
    );
  }

  private handleArticleSchedule(articleData: Articles): void {
    const submissionObservable = this.articlesService.schedule(articleData);

    this.isUploading = true;

    submissionObservable.subscribe(
      () => {
        this.isUploading = false;
        this.isSuccess = true;
        setTimeout(() => {
          this.isSuccess = false;
        }, 2000);

        if (this.isEditing) {
          // @ts-ignore
          this.router.navigate([Route.ARTICLE + Route.SEPARATOR + this.article[ArticlesProperty.url_title] + Route.SEPARATOR + this.article[ArticlesProperty.id]]);
        }
      },
      (error) => {
        console.error('Failed to create/update article:', error);
      }
    );
  }


  // Helper functions

  compareCategory(user1: Categories, user2: Categories) {
    return user1 && user2 ? user1[CategoriesProperty.id] === user2[CategoriesProperty.id] : user1 === user2;
  }

  compareSFn(user1: Surveys, user2: Surveys) {
    return user1 && user2 ? user1[SurveysProperty.id] === user2[SurveysProperty.id] : user1 === user2;
  }

  compareSignature(article1: Articles, article2: Articles) {
    return article1 && article2 ? article1[ArticlesProperty.display_name] === article2[ArticlesProperty.display_name] : article1 === article2;
  }

  public sanitizeEmbed() {
    const embedTypes = ['facebook', 'tiktok', 'x', 'youtube', 'instagram', 'sofascore'];
    this.article[ArticlesProperty.text].forEach((item, index) => {
      for (const type of embedTypes) {
        if (item[type]) {
          this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(item[type]);
          // break; // Stops at the first match per index
        }
      }
    });
    this.cdRef.detectChanges();
  }


  //EMBED CODE PROCESSING
  fetchEmbed(control: AbstractControl, index: number, type: string) {
    const iframe = control.get(type)?.value;
    if (iframe) {
      this.embedSafe[index] = this.sanitizer.bypassSecurityTrustHtml(iframe);
      control.get(type)?.setValue(iframe);
    }
  }

  /*SURVEY RELATED FUNCITONS*/
  updateEmbedField(selectedSurveyId: string, control: AbstractControl) {
    // @ts-ignore
    control.get('survey').setValue('');
    setTimeout(() => {
      // @ts-ignore
      control.get('survey').setValue(selectedSurveyId);
    }, 0);
  }

  /*SURVEY RELATED FUNCITONS*/


  copyCode(link: string) {
    navigator.clipboard.writeText(link).then(() => {
      this.snackBar.open('Link copied to clipboard', 'Dismiss', {
        duration: 3000,
        panelClass: ['snackbar-success']
      });
    }, () => {
      this.snackBar.open('Unable to copy link to clipboard', 'Dismiss', {
        duration: 3000,
        panelClass: ['snackbar-error']
      });
    });
  }

  public isVideo(end: string | undefined | null): boolean {
    // Check if 'end' is defined and ends with 'mp4'
    return typeof end === 'string' && end.endsWith('mp4');
  }

  openImageSelector() {
    const dialogRef = this.dialog.open(TagImagesDisplayDialogComponent, {
      width: '90vw',
      data: {
        tags: this.allTags
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.imageLinks.push(...result)
        // Handle selected images
      }
    });
  }

  isControlType(control: AbstractControl, type: string): boolean {
    return control instanceof FormGroup && control.get(type) !== null;
  }

  isEditingFunction(): boolean {
    return this.isEditing
  }

  /*TEXT RELATED FUNCTIONS*/
  get getText() {
    return this.form.controls["textEditors"] as FormArray;
  }

  addEditor(type: string, extraFields: { [key: string]: any } = {}) {
    const formGroup = this.formBuilder.group({
      [type]: ['', Validators.required],
      ...extraFields
    });

    this.getText.push(formGroup);

    if (type === 'survey') {
      this.surveysService.getSurveys().subscribe(
        (response) => {
          this.surveys = response;
        },
        (error) => {
          console.error('Failed to get channels:', error);
        }
      );
    }
  }

  deleteForm(index: number) {
    this.getText.removeAt(index);
  }

  /*TEXT RELATED FUNCTIONS*/


  protected readonly ArticlesProperty = ArticlesProperty;
  protected readonly ChairProperty = ChairProperty;
  protected readonly Validators = Validators;
}
